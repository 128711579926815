import React from 'react';

import SiteLayout from '../components/site_layout';
import CustomersComponent from '../components/customers';

import SEO from "../components/seo";

const CustomersPage = () => (
  <SiteLayout>
    <SEO title="Customer" />
    <CustomersComponent></CustomersComponent>
  </SiteLayout>
);

export default CustomersPage;
